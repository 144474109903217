<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Admissions</li>
            </ul>
            <h2>Application Forms</h2>
        </div>
    </div>
</div>

<div class="beautiful-div">
        <p style="  
        padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: #333;
        text-align: center;
        margin: 0; 
        line-height: 1.5; 
        font-weight: bold;"
        >For individuals seeking to apply for any of our programs, the application forms can be downloaded below:</p>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/Application Form 2025 January Intake.pdf" target="_blank" class="file-name">Application Form 2025 January Intake</a>
          <span class="file-size">(1447 Kb)</span>
        </div>
        <a href="assets/pdf/Application Form 2025 January Intake.pdf" download class="download-button">Download</a>
      </div>
    </div>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/Application form for short courses with requirements.pdf" target="_blank" class="file-name">Application form for short courses with requirements</a>
          <span class="file-size">(159 Kb)</span>
        </div>
        <a href="assets/pdf/Application form for short courses with requirements.pdf" download class="download-button">Download</a>
      </div>
    </div>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/src/assets/pdf/Application for short courses without requirements.pdf" target="_blank" class="file-name">Application for short courses without requirements</a>
          <span class="file-size">(149 Kb)</span>
        </div>
        <a href="assets/pdf/src/assets/pdf/Application for short courses without requirements.pdf" download class="download-button">Download</a>
      </div>
    </div>
    <div class="file">
      <div class="file-info">
        <img src="assets/img/telone/pdf-icon.png" alt="PDF Icon" class="pdf-icon">
        <div class="file-details">
          <a href="assets/pdf/Degree Application Form.pdf" target="_blank" class="file-name">Degree Application Form</a>
          <span class="file-size">(3609 Kb)</span>
        </div>
        <a href="assets/pdf/Degree Application Form.pdf" download class="download-button">Download</a>
      </div>
    </div>
  </div>
  
